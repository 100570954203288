<template>
  <div class="page-header-index-wide" style="padding: 0 16px">
    <a-card
      :bodyStyle="{ padding: '16px 0px', height: '100%' }"
      :style="{ height: '100%' }"
    >
      <div class="account-settings-info-main">
        <div class="account-settings-info-left">
          <a-menu
            mode="inline"
            :style="{ border: '0', width: 'auto' }"
            :selectedKeys="selectedKeys"
            type="inner"
            @openChange="onOpenChange"
          >
            <a-menu-item v-for="item in menus" :key="item.key">
              <a @click="handleClick(item.name)">
                {{ item.title }}
              </a>
            </a-menu-item>
          </a-menu>
        </div>
        <div class="account-settings-info-right">
          <div class="account-settings-info-title">
            <span>{{ $route.query.name }}</span>
          </div>
          <route-view></route-view>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import RouteView from "@/layout/RouteView";

export default {
  name: "account",
  components: {
    RouteView,
  },
  data() {
    return {
      openKeys: [],
      selectedKeys: [],
      menus:[],
      menusSource: [
        {
          key: "/evaluation-system/team-grade/unit-manage/abbreviation",
          name: "unitAbbreviation",
          title: "单位简称",
          permission: "team-evaluation-unit-abbreviation"
        },
        {
          key: "/evaluation-system/team-grade/unit-manage/top-list-score",
          name: "unitTopListScore",
          title: "单位榜单分数",
          permission: "team-evaluation-unit-top-list-score"
        },
      ],
    };
  },
  created() {
    this.menusSource.forEach((item) => {
      if(this.hasRouter(item.permission)) {
        this.menus.push(item)
      }
    })
  },
  mounted() {
    this.updateMenu();
  },
  methods: {
    onOpenChange(openKeys) {
      this.openKeys = openKeys;
    },
    updateMenu() {
      const routes = this.$route.matched.concat();
      this.selectedKeys = [routes.pop().path];
    },
    handleClick(name) {
      this.$router.push({
        name,
        query: {
          id: this.$route.query.id,
          name: this.$route.query.name,
        },
      });
    },
  },
  watch: {
    $route() {
      this.updateMenu();
    },
  },
};
</script>

<style lang="less" scoped>
.account-settings-info-main {
  width: 100%;
  display: flex;
  height: 100%;
  overflow: auto;

  &.mobile {
    display: block;

    .account-settings-info-left {
      border-right: unset;
      border-bottom: 1px solid #e8e8e8;
      width: 100%;
      height: 50px;
      overflow-x: auto;
      overflow-y: scroll;
    }
    .account-settings-info-right {
      padding: 20px 40px;
    }
  }

  .account-settings-info-left {
    border-right: 1px solid #e8e8e8;
    width: 224px;
  }

  .account-settings-info-right {
    flex: 1 1;
    padding: 8px 30px;

    .account-settings-info-title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 12px;
      background-color: #e6f7ff;
      width: fit-content;
      padding: 4px 16px;
      font-size: 18px;
      margin-left: -16px;
    }
    .account-settings-info-view {
      padding-top: 12px;
    }
  }
}
</style>
